export const MANAGE_SETUP = {
  MANAGE_SETUP: 'manageSetup',
};

export const COMPANY_INFO = {
  VIEW_COMPANY: 'viewCompany',
};

export const LOGS = {
  VIEW_LOGS: 'viewLogs',
};

export const USER = {
  VIEW_USER: 'viewGetUsers',
};

export const METADATATRANSLATOR = {
  MANAGE_METADATATRANSLATOR: 'manageMetaDataTranslator',
};

export const METADATATRANSLATE_JOB = {
  MANAGE_METADATATRANSLATOR_JOB: 'manageMetaDataTranslationJobs',
};

export const SUPPORT = {
  VIEW_FAQS: 'viewFaqs',
  VIEW_CONTACT: 'viewContact',
};

export const DOCUMENTATION = {
  VIEW_USER_DOCUMENTATION: 'viewUserDocumentation',
  VIEW_ST_TRANSLATOR_DOCUMENTATION: 'viewSTTranslatorDocumentation',
  VIEW_TANSLATE_SETUP_DOCUMENTATION: 'viewTranslateSetupDocumentation',
  VIEW_META_TRANSLATOR_DOCUMENTATION: 'viewMetaTranslatorDocumentation',
  VIEW_MTJOB_DOCUMENTATION: 'viewMTJobDocumentation',
  VIEW_SALESFORCE_DOCUMENTATION: 'viewSalesforceDocumentation',
  VIEW_OPEN_APIS_DOCUMENTATION: 'viewOpenAPIsDocumentation',
  VIEW_EXTENSIONS_DOCUMENTATION: 'viewExtensionsDocumentation',
};
