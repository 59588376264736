import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import SplashScreen from 'src/components/loading-screen/splash-screen';
// layouts
import MainLayout from 'src/layouts/main';
// support pages
const Contact = lazy(() => import('src/pages/support/contact'));
const Faqs = lazy(() => import('src/pages/support/faqs'));

export const supportRoutes = [
  {
    path: 'support',
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'contact-us', element: <Contact />, },
      { path: 'faqs', element: <Faqs />, },
    ],
  },
];
