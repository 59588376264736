// utils
import { paramCase } from 'src/utils/change-case';

// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  DOCS: '/docs',
  SUPPORT: '/support',
};

// ----------------------------------------------------------------------

export const paths = {
  /* to be changed &checked if really needed */
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',

  /* to be changed */

  tutorials: '/tutorials',

  auth: {
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
  },

  // SUPPORT
  supportPath: {
    root: ROOTS.SUPPORT,
    faqs: `${ROOTS.SUPPORT}/faqs`,
    contact: `${ROOTS.SUPPORT}/contact-us`,
  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    metadataTranslator: `${ROOTS.DASHBOARD}/metadataTranslator`,
    jobs: `${ROOTS.DASHBOARD}/jobs`,
    metadataTranslateJob: `${ROOTS.DASHBOARD}/MTJobs`,
    metadataTranslateJobDetails: `${ROOTS.DASHBOARD}/MTJobDetails`,
    companyInformation: `${ROOTS.DASHBOARD}/companyInformation`,
    glossary: `${ROOTS.DASHBOARD}/glossary`,
    credentials: `${ROOTS.DASHBOARD}/credentials`,
    approval: `${ROOTS.DASHBOARD}/approval`,
    log: `${ROOTS.DASHBOARD}/Logs`,
    translationEngine: `${ROOTS.DASHBOARD}/translationEngine`,
    security: `${ROOTS.DASHBOARD}/security`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      list: `${ROOTS.DASHBOARD}/user`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
  },

  // DOCS
  docs: {
    root: ROOTS.DOCS,
    overview: `${ROOTS.DOCS}/overview`,
    user: {
      root: `${ROOTS.DOCS}/user`,
      roles: `${ROOTS.DOCS}/user/roles`,
      register: `${ROOTS.DOCS}/user/register`,
      invite: `${ROOTS.DOCS}/user/invite`,
      passwordReset: `${ROOTS.DOCS}/user/passwordReset`,
    },
    STSetup: {
      root: `${ROOTS.DOCS}/STSetup`,
      STSetupApproval: `${ROOTS.DOCS}/STSetup/approval`,
      orgCredentials: `${ROOTS.DOCS}/STSetup/orgCredentials`,
      translationEngine: `${ROOTS.DOCS}/STSetup/translationEngine`,
      glossary: `${ROOTS.DOCS}/STSetup/glossary`,
      notifications: `${ROOTS.DOCS}/STSetup/notifications`,
    },
    metaTranslator: {
      root: `${ROOTS.DOCS}/metaTranslator`,
      retrieveMetadata: `${ROOTS.DOCS}/metaTranslator/retrieveMetadata`,
      selectOrgLanguage: `${ROOTS.DOCS}/metaTranslator/selectOrgLanguage`,
      deployMetadata: `${ROOTS.DOCS}/metaTranslator/deployMetadata`,
      translateMetadata: `${ROOTS.DOCS}/metaTranslator/translateMetadata`,
      limit: `${ROOTS.DOCS}/metaTranslator/limit`,
    },
    MTJob: {
      root: `${ROOTS.DOCS}/MTJob`,
      createUpdateJob: `${ROOTS.DOCS}/MTJob/createUpdateJob`,
      status: `${ROOTS.DOCS}/MTJob/status`,
      compareOrgs: `${ROOTS.DOCS}/MTJob/compareOrgs`,
      orgsDeployment: `${ROOTS.DOCS}/MTJob/orgsDeployment`,
    },
    salesforce: {
      root: `${ROOTS.DOCS}/salesforce`,
      realTimeDataTranslation: `${ROOTS.DOCS}/salesforce/realTimeDataTranslation`,
      languageDetection: `${ROOTS.DOCS}/salesforce/languageDetection`,
      authenticationSalesforce: `${ROOTS.DOCS}/salesforce/authenticationSalesforce`,
      feedItemPostTranslation: `${ROOTS.DOCS}/salesforce/feedItemPostTranslation`,
      emailTranslation: `${ROOTS.DOCS}/salesforce/emailTranslation`,
      installPackage: `${ROOTS.DOCS}/salesforce/installPackage`,
      editRecordPage: `${ROOTS.DOCS}/salesforce/editRecordPage`,
      caseTranslation: `${ROOTS.DOCS}/salesforce/caseTranslation`
    },
    openAPIs: {
      root: `${ROOTS.DOCS}/openAPIs`,
      token: `${ROOTS.DOCS}/openAPIs/token`,
    },
    extensions: {
      root: `${ROOTS.DOCS}/extensions`,
      copado: `${ROOTS.DOCS}/extensions/copado`,
    },
  },
};
