import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import usePermission from 'src/permission/has-permission';
import { DOCUMENTATION, SUPPORT } from 'src/permission/all_permissions';
import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

const icon = (
  name: string // <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
) => (
  // OR
  <Iconify icon={name} />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('mdi:account'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  salesforce: icon('mdi:salesforce'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  library: icon('mdi:library'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  job: icon('iconamoon:synchronize-duotone'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  account: icon('solar:home-bold-duotone'),
  credential: icon('mdi:cloud-key'),
  approval: icon('wpf:approval'),
  security: icon('wpf:key-security'),
  dict: icon('streamline:dictionary-language-book-solid'),
  translation: icon('dashicons:translation'),
  faqs: icon('eos-icons:workload'),
  support: icon('bx:support'),
};

const GetPermissions = () => ({
  docsUserRoles: usePermission(DOCUMENTATION.VIEW_USER_DOCUMENTATION),
  docsRegisterUser: usePermission(DOCUMENTATION.VIEW_USER_DOCUMENTATION),
  docsInviteUser: usePermission(DOCUMENTATION.VIEW_USER_DOCUMENTATION),
  docsPasswordReset: usePermission(DOCUMENTATION.VIEW_USER_DOCUMENTATION),

  docsSTSetupApproval: usePermission(DOCUMENTATION.VIEW_MTJOB_DOCUMENTATION),
  docsOrgCredentials: usePermission(DOCUMENTATION.VIEW_TANSLATE_SETUP_DOCUMENTATION),
  docsTranslationEngine: usePermission(DOCUMENTATION.VIEW_TANSLATE_SETUP_DOCUMENTATION),
  docsGlossary: usePermission(DOCUMENTATION.VIEW_TANSLATE_SETUP_DOCUMENTATION),
  docsNotification: usePermission(DOCUMENTATION.VIEW_TANSLATE_SETUP_DOCUMENTATION),

  docsRetrieveMetadata: usePermission(DOCUMENTATION.VIEW_META_TRANSLATOR_DOCUMENTATION),
  docsSelectOrgLanguage: usePermission(DOCUMENTATION.VIEW_META_TRANSLATOR_DOCUMENTATION),
  docsTranslateMetadata: usePermission(DOCUMENTATION.VIEW_META_TRANSLATOR_DOCUMENTATION),
  docsDeployMetadata: usePermission(DOCUMENTATION.VIEW_META_TRANSLATOR_DOCUMENTATION),
  docsLimit: usePermission(DOCUMENTATION.VIEW_TANSLATE_SETUP_DOCUMENTATION),

  docsCreateUpdateJob: usePermission(DOCUMENTATION.VIEW_MTJOB_DOCUMENTATION),
  docsMTJobStatus: usePermission(DOCUMENTATION.VIEW_MTJOB_DOCUMENTATION),
  docsCompareOrgs: usePermission(DOCUMENTATION.VIEW_MTJOB_DOCUMENTATION),
  docsOrgsDeployment: usePermission(DOCUMENTATION.VIEW_MTJOB_DOCUMENTATION),

  docsInstallAPackage: usePermission(DOCUMENTATION.VIEW_SALESFORCE_DOCUMENTATION),
  docsAuthenticationSalesforce: usePermission(DOCUMENTATION.VIEW_SALESFORCE_DOCUMENTATION),
  docsRealTimeDataTranslation: usePermission(DOCUMENTATION.VIEW_SALESFORCE_DOCUMENTATION),
  docsLanguageDetection: usePermission(DOCUMENTATION.VIEW_SALESFORCE_DOCUMENTATION),
  docsCaseTranslation: usePermission(DOCUMENTATION.VIEW_SALESFORCE_DOCUMENTATION),
  docsFeedItemPostTranslation: usePermission(DOCUMENTATION.VIEW_SALESFORCE_DOCUMENTATION),
  docsEmailTranslation: usePermission(DOCUMENTATION.VIEW_SALESFORCE_DOCUMENTATION),
  docsEditRecordPage: usePermission(DOCUMENTATION.VIEW_SALESFORCE_DOCUMENTATION),

  docsToken: usePermission(DOCUMENTATION.VIEW_OPEN_APIS_DOCUMENTATION),

  docsCopado: usePermission(DOCUMENTATION.VIEW_EXTENSIONS_DOCUMENTATION),

  allowedFaqs: usePermission(SUPPORT.VIEW_FAQS),
  allowedContact: usePermission(SUPPORT.VIEW_CONTACT),
});

export function useDocsData() {
  const {
    docsUserRoles,
    docsRegisterUser,
    docsInviteUser,
    docsPasswordReset,

    docsSTSetupApproval,
    docsOrgCredentials,
    docsTranslationEngine,
    docsGlossary,
    docsNotification,

    docsRetrieveMetadata,
    docsSelectOrgLanguage,
    docsTranslateMetadata,
    docsDeployMetadata,
    docsLimit,

    docsCreateUpdateJob,
    docsMTJobStatus,
    docsCompareOrgs,
    docsOrgsDeployment,

    docsInstallAPackage,
    docsAuthenticationSalesforce,
    docsRealTimeDataTranslation,
    docsLanguageDetection,
    docsCaseTranslation,
    docsFeedItemPostTranslation,
    docsEmailTranslation,
    docsEditRecordPage,

    docsToken,

    docsCopado,

    allowedFaqs,
    allowedContact,
  } = GetPermissions();

  const docsUserItems = useMemo(() => {
    const items = [];

    if (docsUserRoles) {
      items.push({
        title: 'User Roles Blog',
        path: paths.docs.user.roles,
        icon: ICONS.blank,
      });
    }

    if (docsRegisterUser) {
      items.push({
        title: 'Register User Guide',
        path: paths.docs.user.register,
        icon: ICONS.blank,
      });
    }

    if (docsInviteUser) {
      items.push({
        title: 'Invite User Guide',
        path: paths.docs.user.invite,
        icon: ICONS.blank,
      });
    }

    if (docsPasswordReset) {
      items.push({
        title: 'Password Reset Help',
        path: paths.docs.user.passwordReset,
        icon: ICONS.blank,
      });
    }

    return items;
  }, [docsUserRoles, docsRegisterUser, docsInviteUser, docsPasswordReset]);

  const docsSTSetupItems = useMemo(() => {
    const items = [];

    if (docsSTSetupApproval) {
      items.push({
        title: 'Approval Guide',
        path: paths.docs.STSetup.STSetupApproval,
        icon: ICONS.blank,
      });
    }
    if (docsOrgCredentials) {
      items.push({
        title: 'Org Credentials Help',
        path: paths.docs.STSetup.orgCredentials,
        icon: ICONS.blank,
      });
    }

    if (docsTranslationEngine) {
      items.push({
        title: 'Translation Engine Guide',
        path: paths.docs.STSetup.translationEngine,
        icon: ICONS.blank,
      });
    }

    if (docsGlossary) {
      items.push({
        title: 'Glossary Guide',
        path: paths.docs.STSetup.glossary,
        icon: ICONS.blank,
      });
    }

    if (docsNotification) {
      items.push({
        title: 'Notifications Help',
        path: paths.docs.STSetup.notifications,
        icon: ICONS.blank,
      });
    }

    return items;
  }, [
    docsSTSetupApproval,
    docsOrgCredentials,
    docsTranslationEngine,
    docsGlossary,
    docsNotification,
  ]);

  const docsMetaTranslatorItems = useMemo(() => {
    const items = [];
    if (docsRetrieveMetadata) {
      items.push({
        title: 'Retrieve Metadata Guide',
        path: paths.docs.metaTranslator.retrieveMetadata,
        icon: ICONS.blank,
      });
    }

    if (docsSelectOrgLanguage) {
      items.push({
        title: 'Select Org/Language Guide',
        path: paths.docs.metaTranslator.selectOrgLanguage,
        icon: ICONS.blank,
      });
    }

    if (docsTranslateMetadata) {
      items.push({
        title: 'Translate Metadata Help',
        path: paths.docs.metaTranslator.translateMetadata,
        icon: ICONS.blank,
      });
    }

    if (docsDeployMetadata) {
      items.push({
        title: 'Deploy Metadata Guide',
        path: paths.docs.metaTranslator.deployMetadata,
        icon: ICONS.blank,
      });
    }

    if (docsLimit) {
      items.push({
        title: 'Limit Blog',
        path: paths.docs.metaTranslator.limit,
        icon: ICONS.blank,
      });
    }

    return items;
  }, [
    docsRetrieveMetadata,
    docsSelectOrgLanguage,
    docsTranslateMetadata,
    docsDeployMetadata,
    docsLimit,
  ]);

  const docsMTJobItems = useMemo(() => {
    const items = [];
    if (docsCreateUpdateJob) {
      items.push({
        title: 'Create/Update Job Guide',
        path: paths.docs.MTJob.createUpdateJob,
        icon: ICONS.blank,
      });
    }
    if (docsMTJobStatus) {
      items.push({
        title: 'Meta Translate Job Status Help',
        path: paths.docs.MTJob.status,
        icon: ICONS.blank,
      });
    }
    if (docsCompareOrgs) {
      items.push({
        title: 'Compare Orgs Help',
        path: paths.docs.MTJob.compareOrgs,
        icon: ICONS.blank,
      });
    }

    if (docsOrgsDeployment) {
      items.push({
        title: 'Orgs Deployment Guide',
        path: paths.docs.MTJob.orgsDeployment,
        icon: ICONS.blank,
      });
    }
    return items;
  }, [docsCreateUpdateJob, docsMTJobStatus, docsCompareOrgs, docsOrgsDeployment]);

  const docsSalesforceItems = useMemo(() => {
    const items = [];

    if (docsInstallAPackage) {
      items.push({
        title: 'Install a Package Blog',
        path: paths.docs.salesforce.installPackage,
        icon: ICONS.blank,
      });
    }
    if (docsAuthenticationSalesforce) {
      items.push({
        title: 'Authentication and Setup Blog',
        path: paths.docs.salesforce.authenticationSalesforce,
        icon: ICONS.blank,
      });
    }
    if (docsLanguageDetection) {
      items.push({
        title: 'Language Detection Blog',
        path: paths.docs.salesforce.languageDetection,
        icon: ICONS.blank,
      });
    }
    if (docsRealTimeDataTranslation) {
      items.push({
        title: 'RealTime Data Translation Blog',
        path: paths.docs.salesforce.realTimeDataTranslation,
        icon: ICONS.blank,
      });
    }
    if (docsCaseTranslation) {
      items.push({
        title: 'Case Translation Blog',
        path: paths.docs.salesforce.caseTranslation,
        icon: ICONS.blank,
      });
    }
    if (docsFeedItemPostTranslation) {
      items.push({
        title: 'Feed Item Post Translation Blog',
        path: paths.docs.salesforce.feedItemPostTranslation,
        icon: ICONS.blank,
      });
    }
    if (docsEmailTranslation) {
      items.push({
        title: 'Email Translation Blog',
        path: paths.docs.salesforce.emailTranslation,
        icon: ICONS.blank,
      });
    }
    if (docsEditRecordPage) {
      items.push({
        title: 'Edit Record Page Blog',
        path: paths.docs.salesforce.editRecordPage,
        icon: ICONS.blank,
      });
    }

    return items;
  }, [docsLanguageDetection, docsRealTimeDataTranslation, docsAuthenticationSalesforce, docsInstallAPackage, docsCaseTranslation, docsFeedItemPostTranslation, docsEmailTranslation, docsEditRecordPage]);

  const docsOpenAPIsItems = useMemo(() => {
    const items = [];

    if (docsToken) {
      items.push({
        title: 'Token Guide',
        path: paths.docs.openAPIs.token,
        icon: ICONS.blank,
      });
    }

    return items;
  }, [docsToken]);

  const docsExtensionsItems = useMemo(() => {
    const items = [];

    if (docsCopado) {
      items.push({
        title: 'Copado CI/CD Guide',
        path: paths.docs.extensions.copado,
        icon: ICONS.blog,
      });
    }

    return items;
  }, [docsCopado]);

  const supportItems = useMemo(() => {
    const items = [];

    if (allowedFaqs) {
      items.push({
        title: 'Faqs',
        path: paths.supportPath.faqs,
        icon: ICONS.faqs,
      });
    }

    if (allowedContact) {
      items.push({
        title: 'Contact',
        path: paths.supportPath.contact,
        icon: ICONS.support,
      });
    }

    return items;
  }, [allowedFaqs, allowedContact]);

  const data = useMemo(
    () => [
      // DOCUMENTATION
      // ----------------------------------------------------------------------
      {
        subheader: 'User Docs',
        items: docsUserItems,
      },

      {
        subheader: 'ST Setup Docs',
        items: docsSTSetupItems,
      },

      {
        subheader: 'Metadata Translator Docs',
        items: docsMetaTranslatorItems,
      },

      {
        subheader: 'Metadata Translate Job Docs',
        items: docsMTJobItems,
      },

      {
        subheader: 'Salesforce Docs',
        items: docsSalesforceItems,
      },

      {
        subheader: 'Open APIs Docs',
        items: docsOpenAPIsItems,
      },

      {
        subheader: 'Extension Docs',
        items: docsExtensionsItems,
      },

      // SUPPORT
      // ----------------------------------------------------------------------
      {
        subheader: 'Support',
        items: supportItems,
      },
    ],
    [
      docsUserItems,
      docsSTSetupItems,
      docsMetaTranslatorItems,
      docsMTJobItems,
      docsSalesforceItems,
      docsOpenAPIsItems,
      docsExtensionsItems,
      supportItems,
    ]
  );

  return data;
}
